<template>
  <div>
    <Invoice
      ref="InvoiceRef"
      @invoice-created="handleInvoiceCreated"
      @invoice-updated="handleInvoiceUpdated"
      @invoice-deleted="handleInvoiceDeleted"
    />

    <p class="caption pasive--text text-right">{{ totalitems }} resultados</p>

    <v-data-table
      :headers="headers ? headers : defaultHeaders"
      :items="items"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      loading-text="Cargando..."
      :no-data-text="'Sin registros'"
      item-key="id"
      item-class="text-center"
      :class="clickable ? 'row-pointer' : ''"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalitems"
      @click:row="handleRowClick"
    >
      <template slot="item.date" scope="props">
        {{ formatDate(props.item.date) }}
      </template>
      <template slot="item.id" scope="props">
        {{ formatInvoiceId(props.item) }}
      </template>
      <template slot="item.patient" scope="props">
        <span v-if="props.item.patient"
          >{{ props.item.patient.firstName }}
          {{ props.item.patient.lastName }}</span
        >
      </template>
      <template slot="item.location" scope="props">
        {{ props.item.location ? props.item.location.name : "-" }}
      </template>
      <template slot="item.appointment" scope="props">
        {{ props.item.appointment ? props.item.appointment.reason : "-" }}
      </template>
      <template slot="item.totalDiscount" scope="props">
        {{ props.item.totalDiscount }} %
      </template>
      <template slot="item.total" scope="props">
        <div class="my-3">
          <div class="font-weight-bold">
            {{ formatMoney(props.item, props.item.total) }}
          </div>
          <div class="caption">
            {{ props.item.currency }}
          </div>
        </div>
      </template>
      <template slot="item.updatedAt" scope="props">
        {{ formatDateAndTime(props.item.updatedAt) }}
      </template>
      <template slot="item.payment" scope="props">
        <InvoiceBanner :invoice="props.item" :clickable="false" />
      </template>
      <template slot="item.createdBy" scope="props">
        <span v-if="props.item.createdBy"
          >{{ props.item.createdBy.firstName }}
          {{ props.item.createdBy.lastName }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import SearchInput from "../SearchInput.vue";
import Invoice from "./Invoice.vue";
import InvoiceBanner from "./InvoiceBanner.vue";
import { formatCurrency, formatInvoiceId } from "../../helpers/formatters";

export default {
  name: "InvoiceList",
  props: ["clickable", "headers", "height"],
  components: {
    SearchInput,
    Invoice,
    InvoiceBanner,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      defaultHeaders: [
        {
          text: "Código",
          value: "id",
        },
        {
          text: "Fecha",
          value: "date",
        },
        {
          text: "Sede",
          value: "location",
        },
        { text: "Paciente", value: "patient" },
        { text: "Consulta", value: "appointment" },
        { text: "Descuento", value: "totalDiscount" },
        { text: "Importe total", value: "total" },
        { text: "Estado de pago", value: "payment", align: "center" },
      ],
      loading: false,
      options: {},
      totalitems: 0,
      item: null,
      items: [],

      // filters
      dateFrom: "",
      dateTo: "",
      terms: "",
      patientId: "",
      page: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "invoices",
      "invoicesCount",
      "availableCurrencies",
    ]),
  },
  methods: {
    ...mapActions(["fetchInvoices"]),
    formatMoney(invoice, total) {
      return this.formatInvoiceCurrency(invoice, total);
    },
    formatInvoiceId(invoice) {
      return formatInvoiceId(invoice.id);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    formatInvoiceCurrency(invoice, n) {
      if (invoice.currency && invoice.currency !== "DOP") {
        n = n / Number(invoice.currencyRate);
      }
      const currency = this.availableCurrencies.filter(
        (c) => c.value == invoice.currency
      )[0];
      return currency
        ? formatCurrency(n, currency.locale, currency.value)
        : formatCurrency(n);
    },
    handleRowClick(item) {
      this.$refs.InvoiceRef.patient = item.patient;
      this.$refs.InvoiceRef.appointment = item.appointment;
      this.$refs.InvoiceRef.invoice = item;
      this.$refs.InvoiceRef.showInfo();
    },
    handleInvoiceCreated({ invoice }) {
      this.items.unshift(invoice);
      this.totalitems += 1;
    },
    handleInvoiceUpdated({ invoice }) {
      this.items = this.items.map((i) => (i.id === invoice.id ? invoice : i));
    },
    handleInvoiceDeleted({ invoice }) {
      this.items = this.items.filter((i) => i.id !== invoice.id);
    },
    handleClickNew({ choosePatient, patient }) {
      if (patient) {
        this.$refs.InvoiceRef.patient = patient;
      }

      if (choosePatient) {
        this.$refs.InvoiceRef.patient = null;
      }
      this.$refs.InvoiceRef.setToCreate();
    },
    setFiltersAndGetData({ dateFrom, dateTo, term, patientId, locationId }) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.term = term;
      this.patientId = patientId;
      this.locationId = locationId;
      if (!this.loading) {
        this.getData();
      }
    },
    async getData() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      const page = this.page || 1;
      const pageSize = this.pageSize || 15;

      await this.fetchInvoices({
        page,
        pageSize,
        doctorId: this.currentUser.doctor.id,
        patientId: this.patientId,
        locationId: this.locationId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        term: this.term,
        orderBy: [
          {
            column: "invoice.date",
            direction: "DESC",
          },
          {
            column: "invoice.updatedAt",
            direction: "DESC",
          },
        ],
      });

      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    invoices: {
      handler(value) {
        this.items = value || [];
      },
      immediate: true,
    },
    invoicesCount: {
      handler(value) {
        this.totalitems = value || 0;
      },
      immediate: true,
    },
    options: {
      handler(value) {
        if (value) {
          this.page = value.page || 1;
          this.pageSize = value.itemsPerPage || 15;
        }
      },
      deep: true,
    },
  },
};
</script>
